@font-face {
  font-family: "Quicksand-Regular";
  src: url("./assets/fonts/Quicksand-Regular.ttf");
}

@font-face {
  font-family: "Ambasador";
  src: url("./assets/fonts/Ambasador.ttf");
}

@font-face {
  font-family: "Tango";
  src: url("./assets/fonts/Tango.ttf");
}

:root {
  margin: 0;
  padding: 0;
  font-size: 0.95rem;
  font-family: "Montserrat", serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #292e3d;
}

body {
  height: 100%;
  width: 100%;
  margin: 0;
  line-height: 1.8;
}

a {
  color: inherit;
  text-decoration: none;
  font-weight: bold;
}

h1,
h2,
h3 {
  font-family: "Ambasador";
  font-size: 3rem;
  margin: 0;
}
